<template>
  <div id="navcard">
    <v-card height="450" width="256" id="drawer">
      <v-navigation-drawer permanent>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title id="drawer-title">
              {{ $t("message.Company") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list dense nav id="text-style">
          <v-list-item
            id="nav-btn"
            v-for="item in items"
            :key="item.title"
            link
            @click="$router.push({ path: item.route })"
          >
            <v-list-item-content>
              <v-list-item-title class="text-h7">{{
                $t(item.title)
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </v-card>
    <div id="prom-text">
      {{ $t("message.PromText") }}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        { title: "message.NavbarAboutCompany", route: "/company/aboutcompany" },
        // { title: "message.NavbarHistory", route: "/company/history" },
        { title: "message.NavbarLicenses", route: "/company/licenses" },
        { title: "message.NavbarSertif", route: "/company/sertif" },
        { title: "message.NavbarPartn", route: "/company/partn" },
        { title: "message.NavbarClients", route: "/company/clients" },
        // { title: "message.NavbarAboutUs", route: "/company/aboutus" },
        { title: "message.NavbarWorkers", route: "/company/workers" },
        { title: "message.NavbarRequ", route: "/company/requ" },
      ],
    };
  },
};
</script>

<style>
#drawer {
  display: flex;
  font-family: Ubuntu,Arial,sans-serif;
}
#history-card-text {
  padding: 15px;
  background-color: rgb(224, 224, 224);
  width: 85%;
  font: 13px/20px Ubuntu, Arial, sans-serif;
}
#drawer-title {
  font-family: Ubuntu,Arial,sans-serif;
  font-weight: 500;
  letter-spacing: normal;
  font-size: 32px;
  
}
#nav-btn {
  border: 2px solid;
}
#navcard {
  display: block;
}
#prom-text {
  color: #666666;
  font-style: italic;
  padding-top: 40px;
  margin-left: 3%;
  margin-right: 3%;
  width: 250px;
  font-size: 13px;
  font-family: Ubuntu,Arial,sans-serif;
}
#subpage-content {
  height: 100%;
  width: 100%;
  padding-left: 20%;
  padding-right: 20%;
  padding-top: 160px;
  display: flex;
}
#rcol {
  margin-left: 5%;
}
#rcol-incard {
  padding: 30px;
  font-family: Ubuntu,Arial,sans-serif;
}
#incard-title {
  font-weight: 500;
  font-size: 15px;
  
  
}
#about-text {
  padding-top: 20px;
  font: 14px/20px Ubuntu, Arial, sans-serif;
}
#text-style {
  font: 14px/20px Ubuntu, Arial, sans-serif;
}
#pad {
  padding-top: 20px;
}
#workpic {
  margin-top: 20px;
}
#pad-left {
  padding-left: 10px;
}
#backto {
  padding-top: 20px;
}
#backto:hover {
  padding-top: 20px;
  color: #00569c;
  -webkit-text-shadow: 1px 1px 1px #00569c;
  -moz-text-shadow: 1px 1px 1px #00569c;
  text-shadow: 1px 1px 1px #00569c;
}
@media (max-width: 1220px) {
  #subpage-content {
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 220px;
  }
}
@media (max-width: 970px) {
  #navcard {
    display: none;
  }
}
</style>